/* You can add global styles to this file, and also import other style files */
:root {
	--tjx-red: #8f1227;
}

.mat-tjx.mat-toolbar {
	color: white;
	background-color: var(--tjx-red);
}

.mat-mdc-raised-button:not(:disabled).mat-tjx,
.mat-mdc-raised-button:not(:disabled).mat-tjx,
.mat-mdc-flat-button:not(:disabled).mat-tjx,
.mat-mdc-fab:not(:disabled).mat-tjx,
.mat-mdc-mini-fab:not(:disabled).mat-tjx {
	color: #fff;
	background-color: var(--tjx-red);
	border-color: var(--tjx-red);
}

.mat-mdc-progress-spinner.mat-tjx {
	--mdc-circular-progress-active-indicator-color: var(--tjx-red);
}

.mat-tjx .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mat-tjx .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background {
	border-color: var(--tjx-red);
	background-color: var(--tjx-red);
}

.mat-tjx .mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple {
	opacity: var(--mdc-checkbox-selected-focus-state-layer-opacity);
	background-color: var(--tjx-red);
}

.mat-tjx .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
	color: var(--tjx-red);
}

.mat-tjx.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
	--mdc-tab-indicator-active-indicator-color: var(--tjx-red);
	--mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
	--mat-tab-header-pagination-icon-color: #000;
	--mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
	--mat-tab-header-active-label-text-color: var(--tjx-red);
	--mat-tab-header-active-ripple-color: var(--tjx-red);
	--mat-tab-header-inactive-ripple-color: var(--tjx-red);
	--mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
	--mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
	--mat-tab-header-active-focus-label-text-color: var(--tjx-red);
	--mat-tab-header-active-hover-label-text-color: var(--tjx-red);
	--mat-tab-header-active-focus-indicator-color: var(--tjx-red);
	--mat-tab-header-active-hover-indicator-color: var(--tjx-red);
}
